import { Outlet, Navigate } from "react-router-dom";
import { useUserStore } from "../../../zustand/auth";

// 비로그인 유저만 접근 가능
// 로그인 유저 접근 불가
const PublicRoute = () => {
  const {isLoggedIn} = useUserStore();

  return isLoggedIn ? <Navigate to="/" />  : <Outlet />;
};

export default PublicRoute;