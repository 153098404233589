import { useEffect, useState } from "react";
import { storeStore } from "../../../zustand/store";
import Barchart from "./chart";
import { storeUsage } from "../../../apis/store";
import useCalculateTotalAmountByDate, { convertToNivoLineData } from "../../../hook/main/useSumDataByDay";
import BodyStyle from "./style/style";
import useYearMonthStore from "../../../zustand/calendor";
import Calendar from "./calendar/calendar";
import AmountBar from "./amountBar";



const Body = () => {
  const { store } = storeStore();
  const [data, setData] = useState([]);
  const { year, month, incrementMonth, decrementMonth } = useYearMonthStore();
  const {resultDataList,resultMonthList,monthResult,yearResult} = useCalculateTotalAmountByDate(data, month);


  useEffect(() => {
    const requestUsage = async () => {
      try{
        const res = await storeUsage(store?.id as number,year);
        setData(res.usages);
      }catch(error){
        console.log(error)
      }
    }

    requestUsage();
    
  },[store,year])

  return(
    <BodyStyle.Container>
      <Calendar year={year} month={month} leftClick={decrementMonth} rightClick={incrementMonth} />
      <AmountBar year={year} month={month} yearAmount={yearResult} monthAmount={monthResult} />
      <Barchart title={"이달의 매출"} data={[{id:"매출", data : convertToNivoLineData(resultDataList,8,10,'일')}]} />
      <Barchart title={"올해의 매출"} data={[{id:"매출", data : convertToNivoLineData(resultMonthList,5,7,'월')}]} />
    </BodyStyle.Container>
  )
}

export default Body;