
import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useUserStore } from "../../../zustand/auth";

// 비로그인 유저만 접근 가능
// 로그인 유저 접근 불가
const PrivateRoute = () => {
  const {isLoggedIn} = useUserStore();

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;