import styled from "styled-components";


const BodyStyle = {
  Container: styled.div`
    flex : 1;
    min-height: 700px;
  `,
  Calendar : styled.div`
    display : flex;
    width : 300px;
    height : 60px;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  LeftButton : styled.div`
    background-image: url('./assets/icon/direct-left.png');
    width: 30px;
    height : 30px;
    background-repeat : no-repeat;
    background-size : cover;
    margin: 9px;
  `,
  RightButton : styled.div`
    background-image: url('./assets/icon/direct-right.png');
    width: 30px;
    height : 30px;
    background-repeat : no-repeat;
    background-size : cover;
    margin: 9px;
  `,
  CalendarText : styled.div`
    font-size : 35px;
    font-weight : 600;
  `,
  AmountView : styled.div`
    display : flex;
    flex-direction: row;
    width : 90%;
    height : 60px;
    margin: auto;
  `,
  AmountBar : styled.div`
    width : 50%;
    height : 50px;
    margin : 15px;
    border: 3px solid #333333;
    border-radius : 9px;
  `,
  AmountIndex : styled.div`
    width : 100%;
    height : 50%;
    text-align : center;
    font-size : 21px;
    font-weight : 600;
    border-radius : 9px 9px 0 0;
  `,
  Amount : styled.div`
    width : 100%;
    height : 50%;
    font-size : 21px;
    text-align : center;
    font-weight : 600;
    background : #E5E5E5;
    border-radius : 0 0 9px 9px ;
  `,
  CalendarDropBox : styled.ul`
    background: #fff;
    border-radius: 8px;
    position: fixed; 
    top: 160px;
    width: 300px;
    height: 420px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: 1000;
    visibility: hidden;
    transform: translate(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    padding: 10px;
    list-style-type: none;
    
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  `,
  MonthGrid : styled.div`
    width : 240px;
    height : 320px;
    margin : 10px auto 0 auto;
    text-align : center;
    display: grid;
    place-items: center;
    grid-template-columns: 2fr 2fr 2fr; // 1fr 3fr 1fr과 똑같을까..?
    grid-template-rows: 2fr 2fr 2fr 2fr;
  `,
  MonthButton : styled.div`
    width : 50px;
    height : 50px;
    margin : 15px;
    border-radius : 50px;
    text-align : center;
    font-size : 25px;
    line-height: 50px;
    font-weight : 700;
  `
};

export default BodyStyle;