import create, { State } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type YearMonthStore = {
  year: number;
  month: number;
  setYear: (newYear: number) => void;
  setMonth: (newMonth: number) => void;
  incrementMonth: () => void;
  decrementMonth: () => void;
};

// 초기 상태 설정
const useYearMonthStore = create<YearMonthStore>(
    (set) => ({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      setYear: (newYear) => set({ year: newYear }),
      setMonth: (newMonth) => set({ month: newMonth }),
      incrementMonth: () => set((state) => ({ month: state.month % 12 + 1, year: state.month === 12 ? state.year + 1 : state.year })),
      decrementMonth: () => set((state) => ({ month: state.month === 1 ? 12 : state.month - 1, year: state.month === 1 ? state.year - 1 : state.year })),
    }));

export default useYearMonthStore;
