import { reqUser } from "../../apis/auth";
import { useUserStore } from "../../zustand/auth";
import { storeStore } from "../../zustand/store";
import { removeCookie } from "../../utils/cookies"


const useAutoLogin = () => {
  const { isLoggedIn,setUser,setIsLoggedIn } = useUserStore();
  const { setStores,setStore} = storeStore();

  // 로그인 처리 함수
  const login = async () => {
    try {
      const user = await reqUser();
      if (undefined === user) {
        window.location.reload();
      }

      setUser(user.user);
      if(!user.stores || !user.stores.length){
        logout();
        return;
      }

      setStores(user.stores);
      setStore(user.stores[0]);
      setIsLoggedIn(true);
      
    } catch {
      logout();
    }
  };

  // 로그아웃 처리 함수
  const logout = () => {
    // 로그인 상태를 false로 설정
    setIsLoggedIn(false);

    // 쿠키에서 로그인 상태 제거
    removeCookie("refreshToken");
    removeCookie("accessToken");

    window.location.reload();
  };

  // Hook 사용자에게 필요한 정보와 함수를 반환
  return { isLoggedIn, login, logout };
}

export default useAutoLogin;


