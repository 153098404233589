import CommonStyle from "../style/style";

const Footer = () => {

  return (
    <CommonStyle.Footer>
      <CommonStyle.Info>카드사 수수료 및 서비스 이용 수수료가 포함되지 않은 매출 정보입니다</CommonStyle.Info>
      <CommonStyle.Info>-</CommonStyle.Info>
      <CommonStyle.Info>070.8098.4474</CommonStyle.Info>
      <CommonStyle.Info>support@washfun.fun</CommonStyle.Info>
      {/*<CommonStyle.Info>Copyright © 2023 A.G.D All rights reserved.</CommonStyle.Info>*/}
      
  </CommonStyle.Footer>
  )
}

export default Footer;