import './App.css';
import LoginPage from './pages/login';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/main';
import { useEffect } from 'react';
import useAutoLogin from './hook/auth/useAutoLogin';
import PrivateRoute from './components/common/privateRoute/PrivateRoute';
import PublicRoute from './components/common/publicRoute/PublicRoute';

function App() {
  const {isLoggedIn,login} = useAutoLogin();

  useEffect(() => {
    if(isLoggedIn){
      login();
    }
  },[])

  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<MainPage />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
      </Routes>
      
    </div>
  );
}

export default App;
