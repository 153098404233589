import React from 'react';
import Styled from '../../components/main/style';
import Header from '../../components/main/header';
import Body from '../../components/main/body';
import Footer from '../../components/common/footer';

const MainPage = () => {
  return(
    <Styled.Container>
      <Header />
      <Body />
      <Footer />
    </Styled.Container>
  )
}

export default MainPage;