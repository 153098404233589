import styled from "styled-components";
import { primaryColor } from "../../../assets/colors/colors";



const CommonStyle = {
  Container: styled.div`
  `,
  Footer : styled.footer`
    width : 100vw;
    height : 100px;
    text-align: center;
    padding: 30px;
    background-color: #333;
    color: #fff;
  `,
  Info : styled.p`
    margin : 5px;
    font-size : 15px;
  `
};


export default CommonStyle;