import React from 'react';
import Styled from '../../components/login/style';
import Header from '../../components/login/header';
import LoginForm from '../../components/login/loginForm';

const LoginPage = () => {
  return(
    <Styled.Container>
      <Header />
      <LoginForm />
    </Styled.Container>
  )
}

export default LoginPage;